
import lsDialog from '@/components/ls-dialog.vue'
import MaterialSelect from '@/components/material-select/index.vue'
import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import {apiGameTopicCategoryAllLists, apiGameConfigDetails, apiGameConfigSet} from '@/api/setting'
@Component({
    components: {
        MaterialSelect,
        lsDialog
    }
})
export default class GameConfigEdit extends mixins() {
    /** S Data **/

    list: any = {}

  // 分类列表
  categoryList: Array<object> = []

    /** E Data **/

    /** S Methods **/

    onSubmit() {
        apiGameConfigSet({
            ...this.list,
            aid: 0
        }).then(() => {
            this.detail()
            this.$message.success('修改成功!')
        }).catch(() => {
            this.$message.error('数据获取失败!')
        })
    }

  // 初始化文章分类数据
  initCategoryLists() {
    apiGameTopicCategoryAllLists({}).then(res => {
      console.log('res', res)
      this.categoryList = res.lists
    })
  }

    // 详情
    detail() {
        apiGameConfigDetails({
            aid: 0
        }).then(res => {
            this.list = res
        }).catch(() => {
            this.$message.error('数据获取失败!')
        })
    }

    /** E Methods **/
    loadData() {
        return Promise.all([
            this.detail(),
            this.initCategoryLists()
        ])
    }

    async onTabClick(event: any) {
        const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)',
            customClass: 'switch-loading'
        })
        await this.loadData()
        loading.close()
    }

    /** S Life Cycle **/
    async created() {
        this.loadData()
    }
    /** E Life Cycle **/
}
